.news__list {
  display: grid;
  grid-template-columns: 100% 0;
  @include bp(s) {
    grid-template-columns: 12rem auto;
  }
  row-gap: 2rem;
  column-gap: 1rem;
}

.news__card {
  .news__list & {
    display: contents;
  }
}

.news__card__media {
  flex-basis: 10rem;
  flex-shrink: 0;
  width: 100%;
  img {
    width: 100%;
    border-radius: $br;
  }
}

.news__card__eyebrow {
  @extend %caption;
  margin-top: -0.2rem;
  display: block;
}
.news__card__title {
  font-weight: bold;
  h3 {
    margin-bottom: 0;
    margin-top: 0;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.news__card__copy {
  @extend %copy;
  grid-column: 1 / 3;
  width: 100%;
  @include bp(s) {
    grid-column: 2 / 3;
  }
}

.news__card__excerpt {
  display: block;
  margin-bottom: 0.5rem;
}

.news__pagination {
  grid-column: 1 / 3;
  @include bp(s) {
    grid-column: 2 / 3;
  }
  .pager {
    display: flex;
    list-style: none;
  }
  li:first-of-type {
    margin-right: 0.5rem;
  }
}

.post {
  display: grid;
  grid-template-columns: 2fr minmax(18rem, 1fr);
  column-gap: 1rem;
  @include bp(m) {
    column-gap: 2rem;
  }
}

.post__title {
  @extend %sectionTitle;
  margin-bottom: 0.2rem;
}

.post__header {
  margin-bottom: 2rem;
  grid-column: 1 / 3;
}

.post__tags,
.post__research-areas {
  list-style: none;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
  svg {
    position: relative;
    top: -2px;
  }
  li {
    margin-bottom: 0.25rem;
  }
}

.post__eyebrow {
  margin-bottom: 0.2rem;
}

.post__meta {
  grid-column: 1 / 3;
  @include bp(l){
    grid-column: 2 / 3;
  }
  ul:first-child, div:first-child {
    padding-top: 0 !important;
  }
}
.post__thumbnail {
  margin-bottom: 1.5rem;
}
.post__links {
  list-style: none;
  margin-left: 0 !important;
  margin-bottom: 1rem;
}

.post__content {
  @extend %copy;
  grid-row: 2;
  grid-column: 1 / 3;
  @include bp(l) {
    grid-column: 1 / 2;
  }
  img {
    max-width: 100%;
  }
}

.post__introduction {
  font-weight: bold;
  margin-bottom: 1rem;
}
