.home__title {
  font-size: var(--huge);
  font-family: $haas-display;
  font-weight: 900;
  line-height: 1.02;
  letter-spacing: -0.001em;
  hyphens: none;
  max-width: 15em;
}

.home__hero {
  margin-bottom: 3rem;
}
.home__copy {
  @extend %copy;
  padding-top: 1rem;
  max-width: 45em;
  hyphens: none;
  p {
    max-width: none;
  }
}

.home__projects {
  margin-bottom: 2rem;
}

.home__news {
  .news__list {
    display: grid;
    column-gap: 1rem;
    margin-bottom: 1rem;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    @include bp(m) {
      row-gap: 2rem;
      grid-template-columns: 1fr 1fr;
    }
    .news__card__copy {
      grid-column: span 1;
    }
  }
}

.section__title {
  @extend %sectionTitle;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  .button--small {
    margin-left: 1rem;
  }
  @include bp(m) {
    grid-column: span 2;
  }
  &.hidden {
    display: none;
  }
}

.section__title__functions {
  @extend .section__title;
  margin-bottom: 2rem;
}

.home__25jahre__section {
  display:inline;
  @include bp(m) {
    display:flex;
  }
}

.home__25jahre__text {
  width:100%;
  @include bp(m) {
    width:50%;
  }
}

.home__25jahre__image {
  width:100%;
  @include bp(m) {
    width:50%;
    padding-left:20px
  }
}

.home__copy__25Jahre {
  max-width: 45em;
}
