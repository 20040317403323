* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: $haas-text;
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  hyphens: auto;
  text-rendering: optimizeLegibility;
  &.projects, &.home {
    overflow-y: scroll;
  }
  @include bp(m) {
    // Jekyll has a deprecated version of Sass,
    // so we need to capitalise these functions
    grid-template-columns: Max(15%, 9rem) 1fr Max(15%, 9rem);
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: 0ms;
  }
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 3px solid $blue;
}

::selection {
  background: $blue;
  color: white;
}

button {
  -webkit-tap-highlight-color: transparent;
}

abbr {
  text-decoration-thickness: 1px;
}

.skip-link {
  position: fixed;
  top: 0;
  right: 2rem;
  background: $blue;
  color: white;
  padding: 0.4em 0.6em;
  display: inline-block;
  border-radius: $br;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translateY(-100%);
  transition: $fast;
  z-index: 2000;
  &:focus, &:hover {
    text-decoration: underline;
    outline: 0;
    transform: translateY(0);
  }
}

.cms__preview {
  width: 100vw;
  padding: 1rem;
}
