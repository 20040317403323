.contact {
  @extend %copy;
  display: grid;
  @include bp(m){
    grid-template-columns: 50% 50%;
  }
  column-gap: 1rem;
}

.contact__map {
  @extend %caption;
  .map {
    width: 100%;
    display: block;
    @include bp(l){
      width: 120%;
    }
  }
  a {
    @extend %textlink;
  }
}
.contact__copy {
  margin-bottom: 1rem;
  strong {
    hyphens: none;
  }
  h2 {
    margin-bottom: 2rem;;
  }
}