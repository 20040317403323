.site__header {
  grid-column: 2 / 3;
  display: flex;
  padding-top: 0.5rem;
  margin-bottom: 8.5rem;
  @include bp(m) {
    position: relative;
    margin-bottom: 3rem;
  }
}

.site__content {
  grid-column: 2 / 3;
  .projects & {
    @include bp(l){
      grid-column: 2 / 4;
      padding-right: 15%;
    }
  }
}

.site__logo {
  top: 0;
  left: 1rem;
  z-index: 2000;
  position: absolute;
  line-height: 0;
  &:hover, &:focus {
    opacity: .85;
  }
  &:focus {
    outline-color: $black;
  }
  @include bp(m) {
    position: fixed;
  }
  img {
    width: 8rem;
    @include bp(m){
      width: 7.1rem;
    }
    height: auto;
  }
}
.site__footer {
  font-weight: bold;
  padding-top: 4rem;
  padding-bottom: 1rem;
  grid-column: 2 / 3;
}

.footer__nav {
  @extend %caption;
  display: flex;
  flex-flow: column;
  margin-left: -.3rem;
  @include bp(m){
    flex-flow: row;
  }
  a {
    padding: .3rem;
    margin-bottom: .5rem;
    @include bp(m){
      margin-bottom: 0;
      margin-right: 3rem;
    }
    text-transform: capitalize;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
