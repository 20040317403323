.about__copy {
  @extend %copy;
  display: grid;
  column-gap: $base;
  row-gap: $base;
  max-width: 75rem;
  margin-bottom: 2rem;
  @include bp(m) {
    grid-template-columns: 1fr 1fr;
  }
}

.about__head {
  margin-bottom: 3rem;
  display: grid;
  @include bp(m) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-auto-flow: column;
  }
  row-gap: 1rem;
  column-gap: 2rem;
  max-width: 75rem;
}

.about {
  .people__list {
    grid-template-columns: repeat(3, 1fr);
    @include bp(s) {
      margin-right: 1fr;
    }
  }
  .head__text {
    margin-bottom: 0.5rem;
  }
}
.research-areas {
  display: grid;
  @include bp(m) {
    grid-template-columns: 1fr 1fr;
  }
  row-gap: 1rem;
  column-gap: 1rem;
}

.ra {
  position: relative;
  width: 100%;
  &:hover,
  &:focus {
    .ra__intro {
      opacity: 1;
    }
  }
}

.ra__media {
  overflow: hidden;
  width: 100%;
  height: 60vmin;
  pointer-events: none;
  @include bp(m) {
    height: 20vmax;
  }
  background-size: cover;
  background-position: center;
  background-color: $lightblue;
}

.ra__copy {
  @extend %copy;
}
.ra__title {
  position: absolute;
  margin-top: 0 !important;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1000;
  text-shadow: 0 0 2px white;
}

.ra__intro {
  opacity: 0;
  transition: $fast;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  padding-right: 1rem;
  padding-top: 2rem;
  font-size: 3.5vw;
  @include bp(m) {
    font-size: 1.25vw;
  }
  background: rgba(white, 0.85);
  margin-bottom: 0 !important;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  p {
    margin-bottom: 0;
  }
}

.dissertations__list {
  @extend .functions__list;
}

.dissertations__name {
  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}

.dissertations__textlink {
  @extend %textlink;
}

.leadership__distance {
  margin-right: 25%;
}
