.projects__list {
  width: 100%;
  list-style: none;
  display: flex;
  flex-flow: column;
  margin-bottom: 2rem;
  &.hidden {
    display: none;
  }
  &.rows {
    flex-flow: column;
    @include bp(m) {
      flex-flow: row;
      flex-wrap: wrap;
      .project__card {
        margin-right: 1rem;
      }
    }
    .card__image {
      width: 100%;
      height: auto;
      @include bp(m) {
        height: 30vmin;
        width: auto;
      }
    }
  }
  .card__image.default {
    display: block !important;
  }
  .card__image.wide {
    display: none !important;
  }
  .project__card {
    margin-bottom: 1rem;
  }
}
.project__card {
  position: relative;
  display: inline-block;
  &.hidden {
    display: none;
  }
  .card__image {
    display: block;
    width: 100%;
  }
  .card__copy {
    padding-top: 0.25rem;
    hyphens: auto;
  }
  img {
    border-radius: $br;
  }
  .card__meta {
    @extend %caption;
    color: $gray;
  }
  &:hover,
  &:focus {
    h3 {
      text-decoration: underline;
    }
  }
  h3 {
    @extend %copy;
    margin-bottom: 0.2rem;
    line-height: 1.2;
    letter-spacing: 0;
    hyphens: none;
  }
  .placeholder {
    background: lightgray;
    width: 500px;
  }
  &.has-wide-image {
    .card__image.wide {
      display: none;
      @include bp(m) {
        display: block;
      }
    }
    .card__image.default {
      @include bp(m) {
        display: none;
      }
    }
  }
}

.projects__filters {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 60rem;
  button {
    margin-right: 0.35rem;
    margin-bottom: 0.5rem;
    border-radius: $br_button;
  }
  .overflow {
    display: none;
  }
  &.show-all .overflow {
    display: inline-flex;
  }
  .checkbox-checked {
    display: none;
  }
  .active {
    .checkbox-checked {
      display: block;
    }
    .checkbox-blank {
      display: none;
    }
  }
}
.project {
  display: grid;
  grid-template-columns: 2fr minmax(18rem, 1fr);
  column-gap: 2rem;
}
.project__title {
  @extend %sectionTitle;
  margin-bottom: 1rem;
  max-width: 45rem;
  hyphens: none;
}
.project__header {
  grid-column: 1 / 3;
}
.project__thumbnail {
  grid-column: 1 / 2;
  margin-bottom: 1rem;
  img {
    width: 100%;
    height: auto;
  }
}

.project__description {
  font-weight: 700;
  margin-bottom: 1rem;
  letter-spacing: 0;
}

.project__content {
  @extend %copy;
  grid-column: 1 / 3;
  @include bp(l) {
    grid-column: 1 / 2;
  }
}

.project__meta {
  grid-column: 1 / 3;
  @include bp(l) {
    grid-column: 2 / 3;
  }
  a {
    @extend %textlink;
  }
  ul {
    list-style: none;
  }
  h3 {
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.project__funding {
  max-width: 18rem;
  max-height: 5rem;
}
.project__funding__link {
  @extend .project__funding;
  margin-left: 2rem;
}

.project__projectlogo {
  max-width: 100%;
  max-height: 10rem;
}

.link-list {
  li {
    margin-bottom: 0.5rem;
    position: relative;
  }
  a {
    padding-left: 2.125em;
    display: inline-block;
  }
  .arrow {
    position: absolute;
    left: 0;
    top: 0.4em;
  }
}

.project__images__link-list {
  h3 {
    margin-top: 1.5rem !important;
  }
}

.project__images {
  display: none;
  img {
    width: 100%;
  }
  @include bp(l) {
    display: inline;
  }
  li {
    margin-bottom: 1rem;
    padding-left: 0;
  }
}
.project__images__mobile {
  display: inline;
  img {
    width: 100%;
  }
  @include bp(l) {
    display: none;
  }
  li {
    margin-bottom: 1rem;
    padding-left: 0;
  }
}
.project__images__inline {
  display: inline;
  img {
    width: 100%;
  }
  li {
    margin-bottom: 1rem;
    padding-left: 0;
  }
}
.project__people {
  margin-left: 0 !important;
  list-style: none;
  column-gap: 1rem;
  row-gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  @include bp(s) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include bp(m) {
    grid-template-columns: repeat(5, 1fr);
  }
  display: grid;
  @include bp(l) {
    max-width: 20rem;
    grid-template-columns: repeat(2, 1fr);
  }
  img {
    width: 100%;
  }
  li {
    max-width: 10rem;
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.project__people--external {
  @extend .project__people;
  grid-template-columns: repeat(1, 1fr);
  @include bp(m) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include bp(l) {
    grid-template-columns: 1fr;
  }
}

.figure__caption {
  @extend %caption;
}

.figure__description {
  margin-right: 0.25rem;
}

.figure__credits {
  display: inline-block;
  color: $gray;
  a {
    @extend %textlink;
    color: inherit;
  }
}

.sdc__header {
  height:50px; 
  display: flex; 
  align-items: center;
}

.sdc__image {
  height:100%;
  float:left;
  margin-right:20px
}

.sdc__title {
  @extend %sectionTitle;
}
