.single__article {
}

.single__title {
  @extend %sectionTitle;
}
.single__header {
  margin-bottom: 1rem;
}
.single__copy {
  @extend %copy;
  p,
  li {
    max-width: 40em;
  }
  p,
  ul,
  ol {
    margin-bottom: 0.5em;
  }
  ul,
  ol {
    margin-left: 1em;
  }
  h3 {
    margin-top: 0.5em;
  }
}
