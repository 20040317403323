@mixin bp($point) {
  @if $point==xl {
    @media (min-width: 1850px) {
      @content;
    }
  } @else if $point==l {
    @media (min-width: 1000px) {
      @content;
    }
  } @else if $point==m {
    @media (min-width: 700px) {
      @content;
    }
  } @else if $point==s {
    @media (min-width: 500px) {
      @content;
    }
  } @else if $point==landscape {
    @media (orientation: landscape) {
      @content;
    }
  }
}

:root {
  font-size: clamp(16px, calc(1.3vw), 20px);
  --huge: 2.65rem;
  @include bp(m) {
    --huge: 3.88rem;
  }
}

$caption: 0.75rem;
$base: 1rem;
$sectionTitle: 1.583rem;
$black: black;
$white: white;
$blue: #0069b4;
$midblue: #a9c2d4;
$lightblue: #e8eff3;
$fast: 150ms;
$br: 2px;
$br_button: 100px;

$gray: rgb(99, 99, 99);
$lightgray: rgb(209, 208, 208);

$haas-display: "neue-haas-grotesk-display", Neue Haas Grotesk, Helvetica, sans-serif;
$haas-text: "neue-haas-grotesk-text", Neue Haas Grotesk, Helvetica, sans-serif;

%caption {
  font-size: $caption;
  letter-spacing: 0.01em;
  word-spacing: 0.001em;
}

%sectionTitle {
  font-family: $haas-display;
  font-size: $sectionTitle;
  line-height: 1.15;
  hyphens: none;
}

%textlink {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

%copy {
  font-size: $base;
  word-spacing: 0.001em;
  line-height: 1.35;
  letter-spacing: 0.02em;
  @include bp(m) {
    letter-spacing: 0.015em;
  }
  p {
    margin-bottom: 0.5rem;
  }
  p, li {
    max-width: 40em;
  }
  h3 {
    margin-top: 2rem;
    margin-bottom: 0.25rem;
    & + h4 {
      margin-top: .5rem;
    };
  }
  h4 {
    text-transform: uppercase;
    font-size: .85em;
    letter-spacing: .08em;
    font-weight: normal;
    margin-top: 1.5rem;
    margin-bottom: .25rem;
  }
  ul {
    margin-left: 1em;
    margin-bottom: 0.5rem;
  }
  td {
    border: 1px solid $black;
    padding: 0.5rem;
    @extend %caption;
    vertical-align: top;
  }
  p a, li a {
    @extend %textlink;
    color: $blue;
  }
}
