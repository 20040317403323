.site__nav {
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: $fast;
  z-index: 1000;
  .nav-active & {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
  @include bp(m) {
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    justify-content: space-between;
    position: fixed;
    padding-left: Max(15%, 9rem);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: Max(15%, 9rem);
    flex-flow: row;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  ul {
    display: flex;
    list-style: none;
    flex-flow: column;
    @include bp(m) {
      justify-content: flex-start;
      flex-flow: row;
    }
  }
}

.nav__item {
  font-size: var(--huge);
  font-family: $haas-display;
  font-weight: 900;
  @include bp(m) {
    font-weight: 700;
    font-family: $haas-text;
    font-size: $caption;
    margin-right: 5em;
    position: relative;
    &:hover,
    &:focus,
    &.active {
      &:after {
        content: "";
        position: absolute;
        bottom: -0.25rem;
        left: 0;
        right: 0;
        height: 2px;
        background: $black;
      }
    }
  }
}

$bun-height: 4px;
$bun-width: 2rem;

.nav__toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1500;
  cursor: pointer;
  height: $bun-width;
  width: $bun-width;
  background-color: transparent;
  font-size: 0;
  @include bp(m) {
    display: none;
  }
  &:before,
  &:after {
    background: $black;
    height: $bun-height;
    width: $bun-width;
    content: "";
    position: absolute;
    transform-origin: center;
    left: 50%;
    top: 50%;
    transition: $fast;
    border-radius: 1px;
  }
  // top bun
  &:before {
    transform: translateX(-50%) translateY(-1.2 * $bun-height);
    .nav-active & {
      transform: translateX(-50%) rotate(45deg);
    }
  }

  // bottom bun
  &:after {
    transform: translateX(-50%) translateY(1.2 * $bun-height);
    .nav-active & {
      transform: translateX(-50%) rotate(-45deg);
    }
  }
}

.languages {
  @extend %caption;
  display: flex;
  padding-top: 1rem;
  font-size: $base;
  opacity: 0.65;
  cursor: pointer;
  @include bp(m) {
    font-size: $caption;
    padding-top: 0;
  }
  .slash {
    margin-left: 0.2rem;
    margin-right: 0.15rem;
  }
  a {
    pointer-events: all;
    letter-spacing: 0.02em;
    position: relative;
    &:hover,
    &:focus {
      &:after {
        display: block;
      }
    }
    &:last-child {
      border-right: 0;
    }
    &.active {
      &:after {
        display: block;
      }
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      bottom: -0.25rem;
      left: 0;
      right: 0;
      height: 2px;
      background: $black;
    }
  }
}
