.slider {
  margin-bottom: .5rem;
  position: relative;
  .project__card {
    width: 100%;
  }
  .card__media {
    max-height: 50vh;
    overflow: hidden;
  }
  .slides {
    list-style: none;
    position: relative;
  }
  .slide {
    display: none;
    &.active {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
}

.slider__controls {
  display: flex;
  justify-content: flex-start;
  padding-top: .5rem;
  button {
    font-size: 0;
    @include bp(m) {
      position: absolute;
      top: 50%;
    }
    svg {
      margin-right: 0 !important
    }
  }
  .prev {
    left: 0;
    margin-right: .5rem;
    @include bp(m){
      transform: translateY(-100%) translateX(-50%);
    }
    svg {
      transform: rotate(180deg);
    }
  }
  .next {
    right: 0;
    @include bp(m){
      transform: translateY(-100%) translateX(50%);
    }
  }
}
