.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $fast;
  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.lightbox__open {
  cursor: zoom-in;
  background: transparent;
}
.lightbox__media {
  max-width: calc(100vw - 4rem);
  max-height: 80vh;
  display: table;
  transition: $fast;
  transform: scale(0.98);
  cursor: zoom-out;
  img {
    object-fit: contain;
    max-height: 80vh;
  }
  .active & {
    transform: scale(1);
  }
}

.lightbox__caption {
  @extend %caption;
  margin-top: 0.5rem;
  text-align: left;
  display: table-caption;
  caption-side: bottom;
}

.lightbox__credits {
  color: $gray;
  display: inline-block;
}

.lightbox__close {
  @extend .button;
  padding: 0.1rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0;
  svg {
    height: 2rem;
    margin-right: 0.05rem;
  }
}
