.button {
  border: 1px solid $black;
  background: white;
  box-shadow: 0 3px 1px -2px rgba(black, 0.1);
  border-radius: $br_button;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  font-family: $haas-text;
  font-weight: 400;
  &:hover,
  &:focus {
    text-decoration: underline !important;
    background-color: $lightblue;
  }
  svg {
    margin-right: 0.2rem;
    display: inline-block;
    position: relative;
    top: -1px;
    height: 0.9rem;
    &.arrow {
      top: 0;
      margin-right: 0.25rem;
    }
  }
  &.active {
    background-color: $lightblue;
  }
}

.button--disabled {
  @extend .button;
  color: $gray;
  cursor: default;
  border-color: $gray;
  box-shadow: none;
  &:hover,
  &:focus {
    text-decoration: none !important;
    background: transparent
  }
}
.button--small {
  @extend .button;
  @extend %caption;
  padding: 0.15rem 0.4rem;
  padding-top: 0.2rem;
  svg {
    top: -.05em;
  }
}
.button--small--person {
  @extend .button--small;
  vertical-align: bottom;
}
.button--archive {
  &.hidden{
    display: none;
  }
}
