.chip {
  @extend %caption;
  background: $blue;
  border: 1px solid $blue;
  border-radius: $br_button;
  display: inline-block;;
  padding: .2em .55em;
  font-weight: 200;
  color: white;
  width: fit-content;
  white-space: nowrap;
  svg {
    position: relative;
    top: 3px;
    height: 1rem;
  }
}

.chip--inactive {
  @extend .chip;
  padding-top: .25rem;
  background: $lightgray;
  color: $black;
  border: 1px solid $lightgray;
}

.chip--researcharea {
  margin: .4em .4em .4em .4em;
}

.chip__list {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  span{
    white-space: normal;
    margin: .4em .4em .4em .4em;
  }
}
