.person__card {
  display: inline-block;
  text-decoration: none !important;
  .chip {
    margin-top: .2rem;
  }
  &.is-active:hover,
  &.is-active:focus {
    h4 {
      text-decoration: underline;
    }
  }
}

.person__card__copy {
  @extend %caption;
  h4 {
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    letter-spacing: 0;
    hyphens: none;
  }
  svg {
    height: 0.9rem;
    margin-left: 0.25rem;
  }
  path {
    fill: $blue;
  }
  .email {
    @extend %textlink;
  }
  .tenure {
    color: $gray;
    display: block;
  }
}

.people__group {
  margin-bottom: 2rem;
}
.people__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include bp(s) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include bp(m) {
    grid-template-columns: repeat(5, 1fr);
  }
  @include bp(l) {
    grid-template-columns: repeat(6, 1fr);
  }
  column-gap: 1.5rem;
  row-gap: 1rem;
  li {
    width: 100%;
    list-style: none;
    margin-bottom: 1rem;
  }
}

.person__card__media img {
  width: 100%;
  max-width: 300px;
  min-width: 80px;
  border-radius: $br;
}
.person__thumbnail {
  display: none;
  @include bp(l) {
    display: inline;
    img {
      max-height: 20rem;
    }
  }
}
.person__thumbnail__mobile {
  display: inline;
  width: 100%;
  .figure__caption {
    margin-bottom: 1rem;
  }
  @include bp(l) {
    display:none;
  }
}

.person__meta__section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include bp(m) {
    grid-template-columns: 11rem 1fr;
  }
  column-gap: 1rem;
}

.person__contact {
  margin-bottom: 2rem;
}
.person__header {
  display: grid;
  @include bp(s) {
    grid-template-columns: 1fr auto;
  }
  column-gap: 2rem;
  margin-bottom: 4rem;
  p span {
    display: block;
  }
  ul {
    list-style: none;
  }
  a {
    @extend %textlink;
  }
  p,
  ul {
    margin-bottom: 0.5rem;
  }
  .media {
    img {
      max-height: 60vh;
    }
  }
}
.person__body {
  display: grid;
  @include bp(s) {
    grid-template-columns: 1fr 1fr;
  }
  column-gap: 2rem;
}

.person__title {
  @extend %sectionTitle;
  margin-bottom: 0.5rem;
}
.person__status {
  display: block;
  align-items: center;
  margin-bottom: 1rem;
  grid-column: 3 / 1;
  .chip {
    margin-right: 0.5rem;
  }
  .since {
    margin-right: 1rem;
  }
  a {
    margin-top: 5px;
    margin-right: 0.5rem;
  }
}

.person__role {
  li {
    margin-bottom: 0.25rem;
  }
}

.publications__list {
  display: grid;
  grid-template-columns: 5rem 1fr;
  ol {
    grid-column: 1/3;
    list-style: disc;
    margin-bottom: 2rem;
  }
  li {
    display: block;
    margin-bottom: 1rem;
    max-width: 34rem;
  }
}
.publication__title {
  font-weight: normal;
  display: block;
  margin-bottom: 0.125rem;
  hyphens: none;
  line-height: 1.15;
  font-weight: 600;
  font-size: $caption;
  text-decoration: none !important;
  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}
.publication__authors {
  @extend %caption;
}
.publication__meta {
  @extend %caption;
  color: $gray;
  a {
    @extend %textlink;
  }
}

.functions__list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(80px, 20%) minmax(0, 78%);
  @include bp(s) {
    grid-template-columns: minmax(80px, 10%) minmax(0, 39%) minmax(80px, 10%) minmax(0, 35%);
  }
  column-gap: 2%;
  row-gap: 1rem;
  li {
    width: 100%;
    list-style: none;
    margin-bottom: 1rem;
  }
}

.functions__name {
  &:hover,
  &:focus {
    text-decoration: underline !important;
  }
}

.person__card__functions {
  margin-top: 1rem;
  word-wrap: break-word;    
}

.person__card__firstrow {
  @include bp(s) {
    margin-right: 8%;    
  }
}

.hidden {
  display: none;
}
